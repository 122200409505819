/*
 * Copyright 2024 Tridium Inc. All rights reserved.
 */
import React from 'react';
import {
  NiagaraButton2,
  NiagaraModal,
} from '@Niagara-Cloud-Suite/Niagara-Cloud-Suite.NiagaraManagementPlaneCommons-lib';
import { Modal } from '@scuf/common';
import { NiagaraMyUser } from '../../../api/management';
import './UserInviteActionModal.scss';
import { IUserInvitationRequest } from '../../../api/userInvitation';
interface IUserInviteActionModal {
  onConfirm: () => void;
  onCancel: () => void;
  userInvitationDetails: IUserInvitationRequest;
  currentUser: NiagaraMyUser;
}
const UserInviteActionModal = ({
  onConfirm,
  onCancel,
  userInvitationDetails,
  currentUser,
}: IUserInviteActionModal) => {
  const DEFAULT_ORG = 'NIAGARA COMMUNITY';
  const acceptInvite = () => {
    onConfirm();
  };
  return (
    <NiagaraModal className='user-invite-action-modal' open={true} size='small'>
      <Modal.Header>
        <div className='modal-heading'>User Invitation</div>
        <NiagaraButton2
          type='secondary-link'
          icon='Close'
          iconColor='#606060'
          onClick={onCancel}
        />
      </Modal.Header>
      <Modal.Content>
        <div className='modal-content'>
          <p>
            Your partner, {userInvitationDetails.partnerName}, has invited you
            to join the {userInvitationDetails.customerName} organization on
            Niagara Cloud.
          </p>
          {currentUser && currentUser.organizationName !== DEFAULT_ORG && (
            <p>
              Note that accepting this invitation will remove you from your
              current organization {currentUser.organizationName}.
            </p>
          )}
          <p>
            Click 'Accept' below to join this organization
            {currentUser &&
              currentUser.organizationName !== DEFAULT_ORG &&
              `, declining will direct you to current organisation.`}
          </p>
        </div>
      </Modal.Content>
      <Modal.Footer>
        <div className='button-container'>
          <NiagaraButton2
            type='secondary'
            content='Decline'
            onClick={onCancel}
          />
          <NiagaraButton2
            type='primary'
            content='Accept'
            onClick={() => acceptInvite()}
          />
        </div>
      </Modal.Footer>
    </NiagaraModal>
  );
};
export default UserInviteActionModal;
