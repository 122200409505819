/*
 * Copyright 2024 Tridium Inc. All rights reserved.
 */
import { niagaraHttp } from '../../utils/niagaraHttp';

export type IRoleAssignment = {
  role: string;
  scope: string;
};
export type IUserInvitation = {
  customer: string;
  partner: string;
  email: string;
  roleAssignments: IRoleAssignment[];
};

export interface IUserInvitationRequest {
  id: number;
  customerName: string;
  partnerName: string;
}

const MANAGEMENT_API_BASE_URL = `${window.ENV.API_BASE_URL}/api/v2/management`;
export async function sendUserInvitationAsync(body: IUserInvitation) {
  return niagaraHttp.post<boolean>(
    `${MANAGEMENT_API_BASE_URL}/userInvitations`,
    body
  );
}
export async function getMyUserInvitationsAsync() {
  return niagaraHttp.get<IUserInvitationRequest[]>(
    `${MANAGEMENT_API_BASE_URL}/myUser/userInvitations`
  );
}

export async function resendUserInvitationAsync(invitationId: string) {
  return niagaraHttp.post<boolean>(
    `${window.ENV.API_BASE_URL}/api/v2/management/userInvitation/${invitationId}/resend`,
    {}
  );
}
